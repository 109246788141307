<template>
	<div class="contact" ref="contact">	
		<Promo/>	
		<DialogBox>
			<template v-slot:title>
				<h2>CONTÁCTANOS</h2>
			</template>

			<template v-slot:body>
				<div class="contact-info">
					<div>
						<b>Teléfono:</b> <a href="tel:+584122985469">04122985469</a>
					</div>
					<div>
						<b>Email:</b> <a href="mailto:krhistofer@hotmail.com">krhistofer@hotmail.com</a>
					</div>
				</div>
				<div class="form big">
					<div class="field-group" :class="{ 'field-group--error': $v.name.$invalid }">
						<label for="name">Nombre:</label>
						<input type="text" v-model="name">
  						<div class="error" v-if="!$v.name.required">{{ $store.getters.getValidationMessage('required') }} </div>
  						<div class="error" v-if="!$v.name.minLength">{{$v.name.$params.minLength.min}} {{ $store.getters.getValidationMessage('minLength') }}</div>
					</div>
					<div class="field-group" :class="{ 'field-group--error': $v.email.$invalid }">
						<label for="email">Correo Electrónico:</label>
						<input type="email" v-model="email">
						<div class="error" v-if="!$v.email.required">{{ $store.getters.getValidationMessage('required') }}</div>
						<div class="error" v-if="!$v.email.email">{{ $store.getters.getValidationMessage('email') }}</div>
					</div>
					<div class="field-group" :class="{ 'field-group--error': $v.comments.$invalid }">
						<label for="comments">Comentario:</label>
						<textarea v-model="comments" cols="30" rows="10"></textarea>
						<div class="error" v-if="!$v.comments.required">{{ $store.getters.getValidationMessage('required') }}</div>
					</div>			
				</div>				
			</template>			
		</DialogBox>
		<div class="bottom-content">
			<button class="modal-default-button button black big" @click="contact">
				Enviar
			</button>				
			<Social/>			
		</div>
		<Modal :show="response != ''" @close="close" :className="{ 'alert': true, 'error': $v.comments.$invalid }">
			<template v-slot:title>
				<h3>Contacto</h3>			
			</template>
			<template v-slot:form>
				<p>{{ response }}</p>
			</template>
		</Modal>
		<transition appear name="bounce" enter-active-class="bounceInRight">
			<img id="rigth-bars" class="bars hide-mobile" src="../assets/img/rigth-bars.png" alt="">
		</transition>
		<transition appear name="bounce" enter-active-class="bounceInUp">
			<img id="left-bar" class="bars hide-mobile" style="animation-duration: 1.5s" src="../assets/img/left-bar.png" alt="">
		</transition>
		<transition appear name="bounce" enter-active-class="bounceInRight">
			<img id="orange-bars" class="bars hide-mobile" src="../assets/img/orange-bars.png" alt="">
		</transition>			
	</div>
</template>

<script>
import Promo from '../components/Promo.vue'
import DialogBox from '../components/DialogBox.vue'
import Social from '../components/Social.vue'
import Modal from '../components/Modal.vue'
import { required, minLength, email } from 'vuelidate/lib/validators'
const axios = require('axios');

	export default {
		name: 'Contact',
		title: 'Contacto',
		components: {
			Promo,
			DialogBox,
			Social,
			Modal,
		},
		data(){
			return {
				name: '',
				email: '',
				comments: '',
				response: '',
			}
		},
		validations: {
			name: {
				required,
				minLength: minLength(4),
			},
			email: {
				required,
				email,
				minLength: minLength(4),
			},
			comments: {
				required,
				minLength: minLength(4),
			},			
		},
		methods: {
			contact() {

				this.$v.$touch()
				if (this.$v.$invalid) {
					this.response = 'No se pueden enviar datos invalidos'
				} else {
					if (window.location.hostname != 'localhost') {
						this.$store.dispatch('setContactLoading', true)
						axios.post('/api/contacto', {name: this.name, email: this.email, content: this.comments})
							.then(response => {
								this.response = response.data.message
								this.$store.dispatch('setContactLoading', false)
							})
							.catch(error => {
								this.response = error
								this.$store.dispatch('setContactLoading', false)
							})
					} else {
						this.$store.dispatch('setContactLoading', true)
						setTimeout(()=> {
							this.response = 'Contacto enviado satisfactoriamente'						
							this.$store.dispatch('setContactLoading', false)
						}, 2000)
					}

					const el = this.$refs["contact"];
					el.scrollIntoView({behavior: 'smooth'});			

				}
			},
			close() {
				this.response = ''
				this.name = ''
				this.email = ''
				this.comments = ''
			}, 
		},
	}
</script>

<style lang="scss" scoped>

	.contact {
		width: 100%;
		height: 100%;
		margin-top: -48%;

		.contact-info {
			margin: -30px 0 30px;

			div, a {
				color: $orangeTheme;
				font-size: 30px;
				margin: 0;
				padding: 0;
				margin-top: 8px;
				text-align: center;
			}
		}
		
		.bars {
			position: absolute;
			z-index: 1;
		}	

		#rigth-bars {
			right: -3.5%;
			top: -52%;
		}

		#left-bar {
			left: 43.4%;
			top: -14.5%;
		}

		#orange-bars {
			right: 3.1%;
			top: -13.5%;
			animation-duration: 2s;
		}
		
		.bottom-content {
			@include flex-container(flex-start, center, center, nowrap, row-reverse);
			width: 100%;
			padding: 0;
			margin-top: 40px;

			.button {
				margin-right: 49px;
			}
		}
	}

	@include mobile {
		
		.contact {			
			margin-top: 0;

			.bottom-content {
				margin-top: 8px;

				.button {
					margin-right: 10px;
				}
			}
		}
	}

</style>